import React from "react"
import Layout from "../components/layout"
import Card from "../components/card"
import CardLayout from "../components/cardLayout"
import ExternalLink from "../components/externalLink"
import creditCard from "../images/creditCard.svg"
import styles from "./donate.module.css"

const local = [
  { name: "City Bloom", href: "https://www.citybloom.org/" },
  {
    name: "Justice for Amani Kildea",
    href:
      "https://www.change.org/p/morristown-police-department-justice-for-amani-kildea/psf/promote_or_share",
  },
  {
    name: "Iron Bound Community Corporation",
    href: "https://ironboundcc.salsalabs.org/iccgeneraldonations/index.html",
  },
  {
    name:
      "NJISJ: New Jersey Institute for Social Justice- Criminal Justice Reform",
    href: "https://www.njisj.org/donate",
  },
  {
    name: "Newark Water Coalition",
    href: "https://www.newarkwatercoalition.com/",
  },
  {
    name: "ACER: African Career, Education, and Resource",
    href: "https://acerinc.org/donation/",
  },
  {
    name: "SURJ: Showing Up for Racial Justice",
    href: "https://www.showingupforracialjustice.org/donate-to-surj.html",
  },
]

const national = [
  {
    name: "Reclaim the Block: Fund our Broader Movement",
    href:
      "https://docs.google.com/document/d/1yLWGTQIe3967hdc9RSxBq5s6KKZHe-3_mWp5oemd7OA/edit",
  },
  {
    name: "Black Farmer Fund",
    href: "https://www.blackfarmerfund.org/take-action",
  },
  {
    name: "I Run With Maud",
    href: "https://www.gofundme.com/f/i-run-with-maud",
  },
  { name: "Campaign Zero", href: "https://www.joincampaignzero.org/action" },
  { name: "The Birth Revolution", href: "gofundme.com/f/the-birth-revolution" },
  {
    name: "Black Lives Matter",
    href: "https://secure.actblue.com/donate/ms_blm_homepage_2019",
  },
  { name: "NAACP", href: "https://www.naacp.org/campaigns/we-are-done-dying/" },
  { name: "NAACPLDF: Legal Defense Fund", href: "https://www.naacpldf.org/" },
  {
    name: "National Bail Fund Network",
    href: "https://www.communityjusticeexchange.org/nbfn-directory",
  },
  { name: "Emergency Release Fund", href: "https://emergencyreleasefund.com/" },
]

const Donate = () => (
  <Layout>
    <Card>
      <CardLayout headerImageSrc={creditCard}>
        <h1>Donate</h1>

        <p>
          We’ve compiled a list of local and national organizations you can
          donate to- Read up on their missions and open your wallets!
        </p>

        <div className={styles.linksContainer}>
          <div className={styles.column}>
            <h3>Local</h3>
            <ul>
              {local.map(({ name, href }) => (
                <li key={name}>
                  <ExternalLink to={href}>{name}</ExternalLink>
                </li>
              ))}
            </ul>
          </div>
          <div className={styles.column}>
            <h3>National</h3>
            <ul>
              {national.map(({ name, href }) => (
                <li key={name}>
                  <ExternalLink to={href}>{name}</ExternalLink>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </CardLayout>
    </Card>
  </Layout>
)

export default Donate
